import styled from "styled-components";
import { frog5, frogvid } from "../../assets";
import { heroBg } from "../../assets";
import Navbar from "../Navbar";
import HelpCenter from "./HelpCenter";


const Hero = () => {


      return (
            <StyledHero>
                  <Navbar />
                  <div className="padded-container">
                        <div className="container">
                              <div className="row align-items-center justify-content-between">

                                    <div className="col-lg-6 col-md-5 align-self-center mt-5"
                                          data-aos="zoom-in"
                                          data-aos-duration="2000"
                                    >
                                          <div className="round-btn mt-3">
                                                $FLIP
                                          </div>
                                          <h1 className="grey-bold-text fw-bold my-4">
                                                FLIP THE FROG 🐸
                                          </h1>
                                          <p className="imagine grey-text col-md-8">
                                                In the beginning, there was the frog:. Before memes, before computers, even before the internet, there was the frog. The classic pond-leaper will emerge as the original frog, and flip pepe. CTO!!!!
                                          </p>


                                    </div>

                                    <div className="col-md-7 col-lg-5 align-self-center mt-5 mt-md-0"
                                          data-aos="zoom-in"
                                          data-aos-duration="2000"
                                    >
                                          <img src={frog5} alt="Frog Laptop" width="100%" />
                                    </div>
                              </div>

                              <div className="my-4 row justify-content-center">

                                    <div className="video-container">
                                          <video width="600" autoPlay muted controls loop>
                                                <source src={frogvid} type="video/mp4" />
                                                Your browser does not support the video tag.

                                          </video>

                                    </div>
                              </div>
                        </div>
                  </div>

            </StyledHero>
      );
};

export default Hero;

const StyledHero = styled.section`
      background-image: url(${ heroBg });

      .padded-container{
            padding: 0 0 4rem ;
      }

      h1 {
            font-size: 50px;
      }
      p.imagine{
            font-size: 18px;
      }

 .video-container {
            padding: 20px;
            border-radius: 10px;
            max-width: 100%;
            text-align: center;
      }

      .video-container video {
            width: 100%;
            max-width: 100%;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
`;