import { dbpedia, dex, dextool, telegram, twitter, youtube } from "../assets";
import { article1, article2, article3 } from "../assets";



// NAV LINKS
export const navLink = [
      { title: 'About', to: '#' },
      { title: 'Socials', to: '/#socials' },
      { title: 'Contract', to: '/#contract' },
      { title: 'Tokenomics', to: '/#tokenomics' },
      { title: 'Features', to: '/#features' },
];

// socials
export const socials = [
      { img: twitter, url: 'https://x.com/FlipTheFrog_CTO' },
      { img: telegram, url: 'https://t.me/flip_cto' },
      { img: dex, url: 'https://dexscreener.com/solana/762p6jdffylkrzmoj7ssdur8kepp4fobmin5lxcsj4pa' },
      { img: dextool, url: 'https://www.dextools.io/app/en/solana/pair-explorer/762P6jDFFyLkrzmoj7ssDUr8KEpp4fobMiN5LXCsJ4pA?t=1720397052776' },
];

// tokenomics

export const tokenomics = [
      {
            name: 'Ticker',
            token: ': $FLIP'
      },
      {
            name: 'IB Supply',
            token: ''
      },
      {
            name: '0/0 tax',
            token: ''
      },
      {
            name: 'LP Burned',
            token: ''
      },

];

// articles

export const features = [
      {
            img: article1,
            name: 'Medium: Tristan Ettleman',
            age: 'Jun 9, 2023',
            url: 'https://trettleman.medium.com/the-flip-the-frog-cartoons-ranked-66a4ae43597'
      },
      {
            img: article2,
            name: 'TV Tropes: Western Animation',
            age: 'January 24, 2023',
            url: 'https://tvtropes.org/pmwiki/pmwiki.php/WesternAnimation/FlipTheFrog'
      },
      {
            img: article3,
            name: 'Cartoon Research',
            age: 'OCTOBER 5, 2023',
            url: 'https://cartoonresearch.com/index.php/flip-the-frog-the-complete-collection-blu-ray-now-shipping/'
      },
      {
            img: dbpedia,
            name: 'DBpedia: Flip the Frog',
            age: 'February 5, 2023',
            url: 'https://dbpedia.org/page/Flip_the_Frog'
      },
      {
            img: youtube,
            name: 'Youtube: Fiddlesticks UB IWERKS',
            age: 'October 10, 2012',
            url: 'https://www.youtube.com/watch?v=uvw2-8oYeL4'
      },
      {
            img: youtube,
            name: 'Youtube: Eminem - The Real Slim Shady ',
            age: 'September 15, 2010',
            url: 'https://youtu.be/eJO5HU_7_1w?si=NP7UB1OAkIoygEX5&t=175'
      },
];
