import styled from "styled-components";
import { features } from "../../utils/data";



const Features = () => {
      return (
            <>
                  <div id="features"></div>
                  <StyledTestimonial
                        data-aos="zoom-in"
                        data-aos-duration="500"
                  >

                        <div className="container">
                              <div className="row align-items-center  ">
                                    <div className="col-lg-4 mb-5 title text-center text-lg-start"
                                          data-aos="fade-left"
                                          data-aos-duration="1000"
                                          data-aos-delay="500"
                                    >
                                          <h1 className="grey-bold-text text-uppercase">
                                                <span className="pry-bold-text"> FEATURES
                                                </span>
                                          </h1>
                                          <p className="small grey-light-text mt-4">
                                                FLIP THE FROG has been featured in many places and has been reviewed by many people. Here are some of the features from various sources.
                                          </p>

                                    </div>
                                    <div className="col-lg-8 mt-5  mt-lg-0"
                                          data-aos="fade-right"
                                          data-aos-duration="1000"
                                          data-aos-delay="500"
                                    >
                                          <div className="row">
                                                {
                                                      features?.map(({ img, name, age, url }, i) =>
                                                            <div className="col-md-4 mt-5 mt-md-0" key={i}>
                                                                  <div className="card h-100">
                                                                        <div className="card-body">

                                                                              <img src={img} alt={name}
                                                                                    width='100%'
                                                                              />
                                                                        </div>
                                                                        <div className="mt-5 px-2">
                                                                              <h5 className="grey-bold-text">
                                                                                    {name}
                                                                              </h5>
                                                                              <p className="small grey-light-text">{age}</p>

                                                                              <a href={url} target="_blank" rel="noopener noreferrer">
                                                                                    <p className="text-underline mt-3">
                                                                                          Visit {'>>'}
                                                                                    </p>

                                                                              </a>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      )
                                                }
                                          </div>
                                    </div>
                              </div>

                        </div>

                  </StyledTestimonial>
            </>
      );
};

export default Features;

const StyledTestimonial = styled.section`

background: linear-gradient(180deg, #EFFFF4 0%, rgba(199, 255, 216, 0.5) 100%);

      padding: 4rem 0;
      position: relative;

   
      .title {
            h1 {
                  font-size: 50px;
            }

            p {
                  font-size: 20px;
            }
      }

      .card {
            background: transparent;
      }

      .card-body{
            width:100%;
            overflow:hidden;
            border-radius:1rem;
            
            img {
                  border-radius:1rem;
                  width:100%;
                  object-fit: cover;


            }
      }

      a{
            color: green;

            p {
                  font-size: 20px;
                  font-weight: 600;
                  text-decoration: underline;
            }
      }
            `;